import _ from "lodash";
import { version } from "../../project.json";
import config from "../config";
import { Trans, useTranslation } from "react-i18next";
import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "@mui/material";
import {
  faClipboardList,
  faEnvelope,
  faProjectDiagram,
  faRightFromBracket,
  faTelescope,
  faUpload,
} from "@fortawesome/pro-light-svg-icons";

import {
  defaultAvatar,
  sumitLogoShort,
  sumitLogoWhite,
} from "@sumit-platforms/common-assets/images";
import {
  Layout,
  MenuItem,
  Modal,
  Navigation,
  Profile,
  Toaster,
  ContactUsModal,
  UploadManager,
  ErrorPage,
  ContextSelect,
  DisabledClientModal,
} from "@sumit-platforms/ui-bazar";
import { useModal } from "@sumit-platforms/ui-bazar/store";
import { uploadStore } from "@sumit-platforms/ui-bazar/store";
import { getDayPeriod } from "@sumit-platforms/ui-bazar/utils";
import { Client } from "@sumit-platforms/types";
import authService from "../services/authService";

import { useNavigationBlocker, useGlobalData, useUser } from "../store";
import { clientStore } from "../store/client";
import {
  useAttachFFContext,
  useErrorInterceptor,
  useFeatureFlag,
} from "@sumit-platforms/ui-bazar/hooks";

const Dashboard = () => {
  const { t } = useTranslation();
  const languageSwitchFF = useFeatureFlag("languageSwitch");
  const showProjectPageFF = useFeatureFlag("must_projectPage");
  const {
    modalContent,
    pushModalContent,
    popModalContent,
    clearModalContent,
    modalType,
    setModalType,
  } = useModal();
  const { user } = useUser();
  const { client, setClient } = clientStore();
  const attachFFContext = useAttachFFContext();
  const {
    getPendingUploads,
    getActiveUploads,
    getSucceedUploads,
    getProgress,
    getUploadInfoText,
    clearSucceedUploads,
  } = uploadStore((state) => state);
  const { fetchGlobalData, toast } = useGlobalData();
  const { blockNavModal } = useNavigationBlocker();
  const oldExtractFF = useFeatureFlag("oldExtract");
  const newExtract = useFeatureFlag("newExtract");

  useEffect(() => {
    if (user && !_.isEmpty(user?.clients)) {
      let currentClient: Client | null = null;
      if (client) {
        const currentClientId = client.idClient;
        currentClient =
          user?.clients.find((c) => c.idClient === currentClientId) || null;
      }
      const clientToSet = currentClient || user.clients[0] || null;
      setClient(clientToSet);
    }
  }, [user?.clients]);

  useEffect(() => {
    if (client) fetchGlobalData({ idClient: client?.idClient });
  }, [client]);

  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const closeModal = useCallback(() => {
    setModalType("info");
    popModalContent();
  }, [clearModalContent, setModalType]);

  useEffect(() => {
    if (!authService?.auth?.currentUser) {
      navigate("/login");
    }
  }, [authService?.auth?.currentUser]);

  useEffect(() => {
    attachFFContext({
      userId: user?.email || user?.uid || "",
      properties: {
        clientId: client?.idClient?.toString() || "",
      },
    });
    if (client?.disabled) {
      pushModalContent(<DisabledClientModal approve={clearModalContent} />);
    }
  }, [client, user]);

  const pendingUploads = getPendingUploads();
  const activeUploads = getActiveUploads();
  const succeedUploads = getSucceedUploads();
  const uploadProgress = getProgress();
  const uploadInfoText = getUploadInfoText();

  const onNavigationMenuItemClick = (to: string) => {
    if (blockNavModal) {
      return blockNavModal({ to });
    } else {
      return navigate(to);
    }
  };

  const menuItems: MenuItem[] = useMemo(() => {
    const menuItems: MenuItem[] = [
      {
        path: "extract2",
        label: `${t("extract")} 3.0`,
        icon: <FontAwesomeIcon icon={faTelescope} />,
        render: (children: React.ReactNode) => children,
        onClick: () => onNavigationMenuItemClick("/extract2"),
        badge: {
          open: t("beta"),
          close: t("b"),
        },
        hidden: !newExtract,
        toolTipContent: (
          <Trans
            i18nKey="extract_tool_tip_content"
            components={{ br: <br /> }}
          />
        ),
      },
      {
        path: "extract",
        label: t("menu_extract"),
        icon: <FontAwesomeIcon icon={faTelescope} />,
        render: (children: React.ReactNode) => children,
        onClick: () => onNavigationMenuItemClick("/extract"),
        hidden: !oldExtractFF,
      },
      {
        path: "",
        label: t("menu_deliveries"),
        icon: <FontAwesomeIcon icon={faClipboardList} />,
        render: (children: React.ReactNode) => children,
        onClick: () => onNavigationMenuItemClick("/"),

        toolTipContent: (
          <Trans
            i18nKey="deliveries_tool_tip_content"
            components={{ br: <br /> }}
          />
        ),
      },
      {
        path: "upload-files",
        label: t("menu_upload_files"),
        icon: <FontAwesomeIcon icon={faUpload} />,
        render: (children: React.ReactNode) => children,
        onClick: () => onNavigationMenuItemClick("/upload-files"),
      },
      {
        path: "projects",
        label: t("menu_projects"),
        icon: <FontAwesomeIcon icon={faProjectDiagram} />,
        render: (children: React.ReactNode) => children,
        onClick: () => onNavigationMenuItemClick("/projects"),
        hidden: !showProjectPageFF,
      },
      {
        label: t("menu_contact_us"),
        preLabel: t("need_help"),
        icon: <FontAwesomeIcon icon={faEnvelope} />,
        render: (children: React.ReactNode) => children,
        onClick: () => {
          pushModalContent(<ContactUsModal />);
        },
        isBottom: true,
      },
      {
        label: t("menu_logout"),
        icon: <FontAwesomeIcon icon={faRightFromBracket} />,
        render: (children: React.ReactNode) => children,
        onClick: async () => {
          await authService.logout();
          navigate("/login");
        },
        isBottom: true,
      },
    ];

    return menuItems;
  }, [client, newExtract, blockNavModal, onNavigationMenuItemClick]);

  const handleChangeClient = (idClient: any) => {
    const client = user?.clients.find((c) => c.idClient === idClient);
    if (client) {
      setClient(client);
    }
  };

  const contextSelect = useMemo(() => {
    return !_.isEmpty(user?.clients) ? (
      <ContextSelect
        isOpen={isMenuOpen}
        options={
          user?.clients?.map((c) => ({
            id: c.idClient + c.name,
            label: c.name,
            value: c.idClient,
            image: c.image,
          })) || []
        }
        onChange={handleChangeClient}
        value={client?.idClient}
      />
    ) : undefined;
  }, [user?.clients, client?.idClient, isMenuOpen]);

  useErrorInterceptor();

  return (
    <div className="App">
      {authService?.auth?.currentUser && (
        <Layout
          isOpen={isMenuOpen}
          navigation={
            <Navigation
              languageSwitchFF={languageSwitchFF}
              openStateLogo={sumitLogoWhite}
              closeStateLogo={sumitLogoShort}
              menuItems={menuItems}
              isOpen={isMenuOpen}
              toggleIsOpen={() => setIsMenuOpen((prevIsOpen) => !prevIsOpen)}
              version={version}
              versionHistoryLink={config.versionHistory}
              contextSelect={contextSelect}
              profile={
                <Profile
                  greeting={t("greetings_period", {
                    period: t(getDayPeriod()),
                  })}
                  showDetails={isMenuOpen}
                  fullName={user?.firstName + " " + user?.lastName}
                  avatarUrl={defaultAvatar}
                />
              }
            />
          }
        >
          <ErrorBoundary
            showDialog
            fallback={({ error, resetError }) => (
              <ErrorPage
                title={t("something_went_wrong")}
                text={error.message}
                redirectToOnClick={"/"}
              ></ErrorPage>
            )}
            onReset={() => navigate("/")}
          >
            <Outlet />
          </ErrorBoundary>
        </Layout>
      )}
      {modalContent.map((modal, i: number) => (
        <div key={i + "modal"}>
          <Modal closeModal={closeModal} type={modalType}>
            {modal}
          </Modal>
        </div>
      ))}
      {!_.isEmpty([...pendingUploads, ...activeUploads, ...succeedUploads]) && (
        <UploadManager
          pendingUploads={pendingUploads}
          activeUploads={activeUploads}
          succeedUploads={succeedUploads}
          clearSucceedUploads={clearSucceedUploads}
          progress={uploadProgress}
          uploadInfoText={uploadInfoText}
        />
      )}
      <Toaster />
      {toast && (
        <div className="Toaster">
          <Alert severity={toast.severity}>{toast.text}</Alert>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
